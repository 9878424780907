#about {
  transition: all 0.55s ease;

  .subtitle {
    width: 133px;
    padding-top: 2rem;
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    padding: 2.5rem 0 3rem 0;

    .img {
      width: 50%;
    }

    .desc {
      padding: 1rem 2.5rem;
      width: 50%;
      font-size: 1.2rem;
      text-align: justify;

      &__header {
        margin-bottom: 0.5rem;
      }

      &__name {
        color: $primary-color;
        font-weight: bold;
      }
    }
  }
}
