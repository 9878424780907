#slider-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.slider {
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
}
.slider-instant {
  transition: none;
}
.slider-animate {
  margin-left: 0;
}
.slider-extra {
  display: flex;
  flex-flow: row nowrap;
}
.slider-last {
  min-width: 100vw;
  max-width: 100vw;
  overflow: hidden;
}
.slider span {
  margin-right: 30px;
}
.slider .slider-extra {
  margin-right: 0;
}
.slider img {
  height: inherit;
  vertical-align: inherit;
  border: inherit;
  width: auto;
  height: 35px;
}
