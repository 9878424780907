* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
  color: $dark-color;
}

ul {
  list-style: none;
}
