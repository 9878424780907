.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-template-areas:
    ' card1 card2 card3 '
    ' card4 card5 card6 ';
  grid-gap: 2rem;
  justify-content: center;

  .card {
    &--1 {
      grid-area: card1;
    }

    &--2 {
      grid-area: card2;
    }

    &--3 {
      grid-area: card3;
    }

    &--4 {
      grid-area: card4;
    }

    &--5 {
      grid-area: card5;
    }

    &--6 {
      grid-area: card6;
    }
  }
}

#contact .container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-template-areas:
    'info open-hours'
    'info map'
    'form map'
    'form map'
    'form map';
  grid-gap: 2.5rem;

  .info {
    grid-area: info;
  }

  .form-container {
    grid-area: form;
  }

  .open-hours {
    grid-area: open-hours;
  }

  .map-container {
    grid-area: map;
  }
}
