@media (max-width: 1020px) {
  #home .content {
    .desc {
      &__title {
        font-size: 40px;
      }

      &__info {
        font-size: 1.2rem;
      }
    }
  }

  #offer {
    .cards {
      grid-template-areas:
        'card1 card2'
        'card3 card4'
        'card5 card6';
    }

    .partners {
      width: 100%;
    }
  }

  #about .content {
    .img {
      display: none;
    }
    .desc {
      width: 100%;
      padding: 0;
    }
  }

  #contact .container {
    grid-template-areas:
      'info open-hours'
      '. .'
      'form form'
      'map map';

    .map-container {
      margin: 1rem 0;
    }
  }
}

@media (max-width: 700px) {
  .popup {
    height: 100px;
  }
  #home {
    .navbar {
      padding: 0.7rem;
      height: 80px;

      .container {
        padding: 0;
      }

      .logo {
        margin: 0;
        height: 100%;
        width: 56px;
      }

      .nav {
        display: none;
      }

      .hamburger {
        display: block;
        margin-bottom: 0.25rem;
      }
    }
    .content {
      .desc {
        width: 100%;
        text-align: center;

        &__title {
          font-size: 4rem;
        }

        &__info {
          max-width: none;
          font-size: 1.5rem;
        }
      }

      .img {
        display: none;
      }
    }
  }

  #offer .cards {
    grid-template-areas:
      'card1' 'card2'
      'card3' 'card4'
      'card5' 'card6';

    .card--front:hover {
      color: $dark-color;
      background-color: $light-color;

      .card--front__details {
        color: $primary-color;
      }

      .card--front__icon {
        color: $primary-color;
      }
    }
  }

  #about {
    .desc {
      text-align: center !important;
    }
  }

  #contact .container {
    grid-template-areas:
      'info info'
      'open-hours open-hours'
      'form form'
      'map map';
    grid-gap: 0.5rem;

    .info,
    .open-hours {
      justify-self: center;
      text-align: center;
    }
    .desc-title {
      text-align: center;
    }
    .info {
      i {
        display: none;
      }
      &__box {
        display: block !important;

        &:last-child {
          margin-bottom: 13.5px !important;
        }
      }
    }
    .open-hours {
      margin: 0 0 1rem 0;
    }

    .form {
      .btn--primary--form {
        display: block;
        width: 100%;
      }
    }

    .map-container {
      height: 400px;
    }
  }
}

@media (max-width: 520px) {
  .popup {
    font-size: 0.9rem;
    flex-direction: column;
    height: 130px;

    &--text {
      padding: 0 0.5rem;
    }

    .btn__accept--popup {
      margin: 5px 0px;
    }
  }
  #home .content {
    .desc {
      &__title {
        font-size: 2.5rem;
      }

      &__info {
        font-size: 1.5rem;
      }
    }
  }
}
