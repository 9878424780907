#home {
  max-height: 100vh;
  height: 100vh;
  background: $bg-circles no-repeat 100% 0;

  .content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 100px 0;
    overflow-wrap: break-word;

    .desc {
      width: 50%;

      &__title {
        font-size: 4rem;
        font-weight: bold;
        color: $primary-color;
      }

      &__info {
        overflow-wrap: break-word;
        margin: 1.5rem 3px;
        max-width: 31rem;
        font-size: 1.5rem;
      }
    }

    .img {
      width: 50%;
      height: 100%;
    }
  }
}
