.cards {
  padding: 3rem 0;
  position: relative;

  .card-container {
    position: relative;
    height: 385px;
    perspective: 1000px;
    background-color: transparent;

    .rotate {
      transform: rotateY(180deg);
    }

    &:hover .card {
      cursor: pointer;
    }

    .card {
      position: relative;
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;
      transition: all 0.5s ease;

      &--front,
      &--back {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $light-color;
        backface-visibility: hidden;
        border-radius: 10px;
        padding: 1.7rem 2rem;
        text-align: center;
        box-shadow: 3px 5px 10px rgba(#000000, 0.25);
      }

      &--front {
        transition: all 0.5s ease;
        display: flex;
        flex-direction: column;

        &__icon {
          font-size: 5rem;
          color: $primary-color;
          transition: all 0.5s ease;
        }

        &__type {
          margin-top: 1rem;
          font-size: 1.6rem;
          font-weight: 550;
        }

        &__desc {
          margin-top: 1rem;
          font-weight: 500;
        }

        &__details {
          font-size: 1.4rem;
          margin-top: auto;
          border-radius: 8px;
          color: $primary-color;
          transition: all 0.5s ease;
          justify-self: center;
        }

        &:hover {
          color: $light-color;
          background-color: $primary-color;

          .card--front__details {
            color: $light-color;
          }

          .card--front__icon {
            color: $light-color;
          }
        }
      }

      &--back {
        background-color: $primary-color;
        color: $light-color;
        text-align: start;
        padding: 1.4rem 2rem;

        transform: rotateY(180deg);

        &__title {
          font-size: 1.31rem;
          font-weight: 500;
          margin-bottom: 0.8rem;
        }

        &__types {
          list-style: square;
          font-size: 1rem;
        }
      }
    }
  }
}
