.navbar {
  position: absolute;
  top: 0;
  height: 100px;
  width: 100%;
  z-index: 250;
  transition: all 0.4s ease-out;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .logo {
      height: 80%;
      width: 63px;
      margin-bottom: 0.465rem;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .hamburger {
      display: none;
      padding-bottom: 0.25rem;
      padding-right: 0.25rem;
      li {
        width: 35px;
        height: 5px;
        background-color: $dark-color;
        margin: 6px 0;
      }
    }

    .nav {
      display: flex;
      padding-bottom: 1rem;

      .active {
        color: $primary-color;
      }

      &__item {
        &--link {
          font-size: 24px;
          cursor: pointer;
          transition: 0.3s all ease-out;
          margin: 0 0.8rem;

          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.sticky {
  position: fixed;
  height: 80px;
  background-color: $light-color;
  border-bottom: 1px solid $medium-color;
}
