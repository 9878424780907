.btn {
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.4rem;
  border: 3px solid $dark-color;
  padding: 0.8rem;
  transition: all 0.3s ease-out;
  display: inline-block;

  &--primary {
    border-color: $primary-color;

    &:hover {
      background-color: $primary-color;
      color: $light-color;
    }

    &:focus {
      background-color: transparent;
      color: $dark-color;
    }

    &--form {
      border-width: 2px;
      padding: 0.5rem;
    }
  }

  &__exit--modal {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 3;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 2px 11px 6px 11px;
    transition: background-color 0.7s;
  }

  &__exit--modal2 {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 3;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 2px 11px 6px 11px;
    transition: background-color 0.7s;
  }

  &__open--modal {
    margin-bottom: 15px;
  }

  &__accept--popup {
    color: $light-color;
    font-size: 1rem;
    margin-left: 1rem;
    padding: 0.4rem;
    background-color: $primary-color;
    border-radius: 5px;

    &:hover {
      background-color: $primary-color;
      color: $light-color;
    }

    &:focus {
      background-color: transparent;
      color: $light-color;
    }
  }

  &__close--popup {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 3;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 2px 11px 6px 11px;
    transition: background-color 0.7s;
    color: $light-color;
  }
}

.btn__open--modal2 {
  border: none;
  outline: none;
  font-size: 1.1rem;
  margin: 0.4rem 0 1rem 0;
  cursor: pointer;
  background-color: transparent;

  &:focus {
    background-color: transparent;
  }

  &:hover {
    background-color: transparent;
  }
}
