.popup {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $dark-color;
  color: $light-color;
  height: 60px;
  z-index: 1000;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;

  &--text {
    text-align: center;
  }
}
