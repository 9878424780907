body {
  font-family: 'Montserrat', sans-serif;
  position: relative;
  color: $dark-color;
}

.container {
  max-width: $website-width;
  padding: 0 1.5rem;
  margin: auto;
  overflow: hidden;
  height: 100%;
  position: relative;
}

.subtitle {
  color: $dark-color;
  font-size: 2.8rem;
  padding-top: 2.5rem;
  border-bottom: 4px solid $primary-color;
  display: flex;
  width: 143px;
  margin: auto;
}

.hidden {
  opacity: 0;
  transform: translateX(100%);
}

.section--hidden {
  opacity: 0;
  transform: translateY(128px);
}
