.form {
  width: 100%;
  margin-top: 5px;

  .form__spinner {
    display: none;
    position: relative;
    width: 80px;
    height: 80px;
    padding-top: 10px;
  }
  .form__spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid $primary-color;
    border-radius: 50%;
    animation: form__spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary-color transparent transparent transparent;
  }
  .form__spinner div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .form__spinner div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .form__spinner div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes form__spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &__group {
    position: relative;
    height: 50px;
    width: 100%;
    font-size: 16px;
    overflow: hidden;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
      -webkit-transition-delay: 9999s;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    &__input {
      width: 100%;
      height: 100%;
      color: $dark-color;
      padding-top: 20px;
      border: none;
      outline: none;
      background-color: transparent;
    }

    &__input:focus + &__label::after,
    &__input--filled + &__label::after {
      transform: translateX(0%);
      border-color: $primary-color;
    }

    &__input--error + &__label::after {
      border-color: $error-color;
    }

    &__input--error + &__label {
      border-color: $error-color;
    }

    &__input:focus + &__label &__label__text,
    &__input--filled + &__label &__label__text {
      transform: translateY(-100%);
      font-size: 14px;
      color: $primary-color;
    }

    &__input--error + &__label &__label__text {
      color: $error-color;
    }

    &__label {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      pointer-events: none;
      border-bottom: 1px solid $dark-color;

      &__text {
        position: absolute;
        bottom: 5px;
        left: 0px;
        color: $dark-color;
        transition: all 0.3s ease;
      }
    }

    &__label::after {
      content: '';
      position: absolute;
      left: 0px;
      bottom: -1px;
      height: 100%;
      width: 100%;
      border-bottom: 3px solid $primary-color;
      transform: translateX(-100%);
      transition: transform 0.3s ease;
    }

    &:nth-last-of-type(2) {
      height: 150px;
      margin-top: 20px;

      textarea {
        resize: none;
        height: 80%;
        padding-top: 5px;
        overflow: auto;
      }

      .form__group__label {
        border: 1px solid $dark-color;
        transition: border 0.1s linear;
        margin-top: 5px;

        &__text {
          top: 15px;
          left: 3px;
        }
      }

      .form__group__input {
        margin-top: 20px;
        padding-left: 7px;
      }

      .form__group__label::after {
        border: none;
      }

      .form__group__input:focus + .form__group__label,
      .form__group__input--filled + .form__group__label {
        border: 3px solid $primary-color;
      }

      .form__group__input--error + .form__group__label {
        border: 3px solid $error-color;
      }

      .form__group__input:focus + .form__group__label .form__group__label__text,
      .form__group__input--filled
        + .form__group__label
        .form__group__label__text {
        transform: translateY(-15px);
      }

      .form__group__input--error
        + .form__group__label
        .form__group__label__text {
        color: $error-color;
      }
    }
  }

  .form__info {
    color: $primary-color;
    padding-top: 5px;

    &--error {
      color: $error-color;
    }
  }

  .btn {
    margin-top: 20px;
    width: 30%;
  }
}
