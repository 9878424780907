#contact {
  background-color: $medium-color;
  transition: all 0.55s ease;

  .subtitle {
    width: 180px;
  }

  .container {
    padding-bottom: 3rem;

    .desc-title {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0.8rem;
    }

    .info {
      margin-top: 2.5rem;

      .company-name {
        font-weight: bold;
      }
      .info__box {
        display: flex;
        align-items: center;
        margin-bottom: 13.5px;

        &:last-child {
          margin-bottom: 0;
        }

        i {
          width: 40px;
          color: $primary-color;
          font-size: 1.9rem;
        }

        p {
          margin-bottom: 3px;
          color: $dark-color;
          font-size: 1.1rem;
        }
      }
    }

    .open-hours {
      margin-top: 2.5rem;
    }
  }

  .form-container {
    .desc-title {
      margin: 0;
    }
  }

  .map-container {
    height: 487px;

    .map {
      box-shadow: 3px 5px 10px rgba(#000000, 0.25);

      width: 100%;
      height: 100%;
      &__info {
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 0.9rem;

        &__link {
          color: $primary-color;
          font-weight: bold;
        }
      }
    }
  }
}
