#offer {
  transition: all 0.55s ease;
  background-color: $medium-color;

  .partners {
    height: 35px;
    width: 80%;
    margin: 0 auto 3rem auto;
  }
}
