.modal2 {
  visibility: hidden;
  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 960px;
    max-width: 100%;
    height: 0px;
    max-height: 95%;
    z-index: 111111;
    transition: all 0.6s ease;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;

    &__header {
      padding: 15px 0;
      visibility: hidden;
    }

    &__list {
      visibility: hidden;
      overflow: auto;
      padding: 0rem 2rem 2rem 2rem;

      p {
        margin-top: 0.5rem;
      }

      h3 {
        margin: 1rem 0;
      }
    }

    img {
      margin: 0 5px 0 10px;
      width: 32px;
      height: 32px;
    }
  }

  &--overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    overflow: auto;
    z-index: 1111;
    transition: all 0.6s ease;
  }
}

.modal {
  visibility: hidden;
  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 960px;
    max-width: 100%;
    height: 0px;
    max-height: 100%;
    z-index: 111111;
    transition: all 0.6s ease;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;

    &__header {
      padding: 15px 0;
      visibility: hidden;
    }

    li:nth-child(odd) {
      background-color: #ddd;
    }

    &__list {
      visibility: hidden;

      li {
        padding: 5px 0;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        span {
          vertical-align: middle;
          line-height: 34px;
        }
      }
    }

    img {
      margin: 0 5px 0 10px;
      width: 32px;
      height: 32px;
    }
  }

  &--overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    overflow: auto;
    z-index: 1111;
    transition: all 0.6s ease;
  }
}
