.sidebar {
  position: fixed;
  z-index: 350;
  background-color: $medium-color;
  width: 63%;
  height: 100%;
  top: 0;
  right: 0;
  transition: all 0.4s ease-out;
  overflow: auto;

  .nav {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    &__item {
      padding: 0.7rem;
      background-color: #3498db;
      margin-bottom: 0.3rem;

      &--link {
        color: $light-color;
        font-size: 24px;
        cursor: pointer;
        transition: 0.2s color ease-in;
        width: 100%;
        height: 100%;
        display: inline-block;

        &:hover {
          color: $dark-color;
        }
      }
    }
  }

  .logo {
    padding-top: 1rem;
    padding-bottom: 0 !important;
    width: 70px !important;
    height: 75px !important;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
