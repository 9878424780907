$website-width: 1440px;

$light-color: #ffffff;
$medium-color: #ecf0f1;
$dark-color: #333;
$primary-color: #3498db;
$error-color: rgb(190, 68, 68);

$bg-circles: url('../images/bg-circles.svg');
$bg-ppl: url('../images/bg-ppl.svg');
$bg-about: url('../images/bg-about.svg');
